import React, {useContext, useEffect, useState} from 'react';
import './index.scss';
import InputWithLabel from "../../components/common/InputWithLabel";
import Select from "../../components/common/Select";
import useValidatedState, {validationFuncs, validationDateFuncs} from "../../hooks/useValidatedState";
import Textarea from "../../components/common/Textarea";
import Button from "../../components/common/Button";
import ProfileImage from '../../icons/mock/profile1.jpg'
import ProfileCover from '../../icons/mock/profile-cover1.jpg'
import NotificationContext from "../../context/NotificationContext";
import {reformatDate, scrollToTop, unreformatDate} from "../../utils/common";
import {instance} from "../../api/constants";
import {User} from "../../types/user";
import CameraIcon from '../../icons/camera'
import {fileInstance, API_URL} from "../../api/constants";
import setSCRFCookie from "../../api/common";

const PersonalInfo = () => {
  const {displayNotification} = useContext(NotificationContext)

  const [[firstName, setFirstName], isFirstNameValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[lastName, setLastName], isLastNameValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[gender, setGender], isGenderValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[location, setLocation], isLocationValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[birthdate, setBirthdate], isBirthdateValid] = useValidatedState("" as string, validationDateFuncs.dateIsNotGreaterThanToday)
  const [[email, setEmail], isEmailValid] = useValidatedState("" as string, validationFuncs.isEmail)
  const [[username, setUsername], isUsernameValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[aboutInfo, setAboutInfo], isAboutInfoValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [avatarLink, setAvatarLink] = useState<string>("")
  const [avatarBackgroundLink, setAvatarBackgroundLink] = useState<string>("")

  const genders = ['female', 'male', 'other']
  const resetAllFields = () => {
    setFirstName('')
    setLocation('')
    setLastName('')
    setGender('')
    setBirthdate('')
    setEmail('')
    setUsername('')
    setAboutInfo('')
  }
  const uploadPersonalData = async () => {
    scrollToTop()
    if (!isProfileInfoValid) {
      displayNotification(
        'error',
        'Personal data invalid',
        'All fields and photos should be complete'
      )
      return
    }

    try {
      const reformatBirth = reformatDate(birthdate)
      const userData = {
        first_name: firstName,
        last_name: lastName,
        username,
        gender: genders.indexOf(gender),
        location,
        birth: reformatBirth,
        email,
        description: aboutInfo,
        avatar: avatarLink ==='' ? undefined : avatarLink ,
        avatar_background: avatarBackgroundLink  ==='' ? undefined : avatarBackgroundLink
      }
      await setSCRFCookie()
      const response = await instance.post('/user/update', userData)
      const responseUserData: User = response.data.data

      setFirstName(responseUserData.first_name)
      setLastName(responseUserData.last_name)
      setGender(responseUserData.gender)
      setLocation(responseUserData.location)
      setBirthdate(unreformatDate(responseUserData.birth))
      setEmail(responseUserData.email)
      setUsername(responseUserData.username)
      setAboutInfo(responseUserData.description)
      setAvatarLink(responseUserData.avatar)
      setAvatarBackgroundLink(responseUserData.avatar_background)

      displayNotification(
        'default',
        'Success',
        'Personal data updated!'
      )

    } catch (e) {
      displayNotification(
        'error',
        'Update error',
        'Something went wrong!'
      )
      console.log(e)
    }
  }
  const getUserData = async () => {
    try {
      const response = await instance.get('/user/me')
      const userData: User = response.data.data
      setFirstName(userData.first_name || "")
      setLastName(userData.last_name || "")
      setGender(userData.gender || "")
      setLocation(userData.location || "")
      setBirthdate(unreformatDate(userData.birth) || "")
      setEmail(userData.email || "")
      setUsername(userData.username || "")
      setAboutInfo(userData.description || "")
      setAvatarLink(userData.avatar || "")
      setAvatarBackgroundLink(userData.avatar_background || "")
    } catch (e) {
      console.log(e)
    }
  }
  const handleAvatarFileChange = async (event: any, isAvatarUpload: boolean) => {
    if (event.target.files[0]) {
      try {
        const file = new FormData()
        file.append('avatar', event.target.files[0])
        await setSCRFCookie()
        const response = await fileInstance.post('/file/user/avatar', file)
        if (response.status === 200) {
          if (isAvatarUpload) {
            setAvatarLink(response.data.path)
          } else {
            setAvatarBackgroundLink(response.data.path)
          }
        }

      } catch (e) {
        console.log(e)
      }
    }
  }

  const isProfileInfoValid =
    isFirstNameValid &&
    isLastNameValid &&
    isGenderValid &&
    isLocationValid &&
    isBirthdateValid &&
    isEmailValid &&
    isUsernameValid &&
    isAboutInfoValid

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <section className="creator-info-area" data-background="">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-8">
            <div className="creator-info-details mb-40 wow fadeInUp">
              <label className="creator-cover-img pos-rel file-select">
                <div className="select-button">
                  <CameraIcon/>
                </div>
                <input
                  type="file"
                  onChange={(event) => handleAvatarFileChange(event, false)}
                />
                {avatarBackgroundLink ?
                  <img
                    src={`${API_URL}/${avatarBackgroundLink}`}
                    alt="profile-img"
                  />
                  :
                  <div className="no-cover">
                    Select background image
                  </div>
                }
              </label>
              <div className="creator-img-name">
                <label className="profile-img pos-rel file-select">
                  <div className="select-button">
                    <CameraIcon/>
                  </div>
                  <input
                    type="file"
                    onChange={(event) => handleAvatarFileChange(event, true)}
                  />
                  {avatarLink ?
                    <img
                      src={`${API_URL}/${avatarLink}`}
                      alt="profile-img"
                    />
                    :
                    <div className="no-image">
                      Select profile image
                    </div>
                  }
                </label>
                <div className="creator-name-id">
                  <h4 className="artist-name pos-rel">
                    {
                      firstName && lastName ?
                        <span>{firstName} {lastName}</span>
                        :
                        <span></span>
                    }
                  </h4>
                  <div className="artist-id">
                    {
                      username ?
                      <span>@{username}</span>
                      :
                      <span></span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="creator-info-personal mb-40 wow fadeInUp">
              <div className="row personal-info-form">
                <div className="col-md-6">
                  <InputWithLabel
                    inputProps={{
                      placeholder: 'Your first name',
                      type: 'text',
                      value: firstName
                    }}
                    onChangeRaw={setFirstName}
                    label={'First Name'}
                    isValid
                  />
                </div>
                <div className="col-md-6">
                  <InputWithLabel
                    inputProps={{
                      placeholder: 'Your last name',
                      type: 'text',
                      value: lastName
                    }}
                    onChangeRaw={setLastName}
                    label={'Last Name'}
                    isValid
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    currentValue={gender}
                    label={'Gender'}
                    values={genders}
                    onChangeRaw={setGender}
                  />
                </div>
                <div className="col-md-6">
                  <InputWithLabel
                    inputProps={{
                      placeholder: 'Your location',
                      value: location,
                    }}
                    onChangeRaw={setLocation}
                    label={'Location'}
                    isValid
                  />
                </div>
                <div className="col-md-6">
                  <InputWithLabel
                    inputProps={{
                      placeholder: 'Date of Birth',
                      type: 'date',
                      value: birthdate,
                    }}
                    onChangeRaw={setBirthdate}
                    label={'Date of Birth'}
                    isValid={isBirthdateValid}
                    incorrectFieldMessage={'Date should be in the past '}
                  />
                </div>
                <div className="col-md-6">
                  <InputWithLabel
                    inputProps={{
                      placeholder: 'Email',
                      type: 'email',
                      value: email,
                    }}
                    onChangeRaw={setEmail}
                    label={'Email'}
                    isValid={isEmailValid}
                    incorrectFieldMessage={'Incorrect email'}
                  />
                </div>
                <div className="col-md-12">
                  <InputWithLabel
                    inputProps={{
                      placeholder: 'Username',
                      type: 'text',
                      value: username,
                    }}
                    onChangeRaw={setUsername}
                    label={'Username'}
                    isValid
                  />
                </div>
                <div className="col-md-12">
                  <Textarea
                    textareaProps={{
                      placeholder: 'Profile description...',
                      value: aboutInfo
                    }}
                    onChangeRaw={setAboutInfo}
                    label={'Profile description'}
                  />
                </div>
              </div>
              <div className="personal-info-btn">
                <Button
                  fontSize={16}
                  onClick={uploadPersonalData}
                  isValid={isProfileInfoValid}
                  background={'green'}
                >
                  <span>Update Now</span>
                </Button>
                <Button
                  fontSize={16}
                  onClick={resetAllFields}
                  isValid
                  background={'orange'}
                >
                  <span>Reset All</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalInfo;
