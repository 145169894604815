import React, {useEffect, useState} from 'react';
import Button from '../../../components/common/Button';
import styled from 'styled-components';
import './index.scss';
import {API_URL, instance} from "../../../api/constants";
import {useNavigate} from "react-router-dom";
import setSCRFCookie from "../../../api/common";

interface SimpleUserCardProps {
  image: string;
  firstName: string;
  lastName: string;
  username: string;
  numberOfCreatedNFTS: number,
  uuid: string
}

const ButtonWrapper = styled.div`
  width: 80px;
  height: 40px;
`

const SimpleUserCard = (props: SimpleUserCardProps) => {
  const {image, firstName, lastName, username, numberOfCreatedNFTS, uuid} = props;

  const [isFollowed, setIsFollowed] = useState<Boolean | null>(null)

  const navigate = useNavigate()

  const setSubscription = async () => {
    try {
      const response = await instance.post(`/user/subscribe/${uuid}`)
          .then(()=> setIsFollowed(true))
    } catch (e) {
      console.log(e)
    }
  }

  const deleteSubscription = async () => {
    try {
      console.log(2222)
      await setSCRFCookie()
      const response = await instance.post(`/user/unsubscribe/${uuid}`).then(()=> setIsFollowed(false))

    } catch (e) {
      console.log(e)
    }
  }

  const checkSubscription = async () => {
    try {
      const response = await instance.get(`/user/subscribe/${uuid}`)
      setIsFollowed(response.data.result)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    checkSubscription()
  }, [])

  return (
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <div className="creator-single creator-single-short creator-single-filled">
        <div className="creator-wraper">
          <div className="creator-inner">
            <div className="creator-content pos-rel creator-short-content">
              <div className="profile-img pos-rel">
                <img src={`${API_URL}/${image}`} alt="profile-img"/>
              </div>
              <div className="creator-info">
                <div className="">
                  <h4 className="artist-name pos-rel">
                    <span className={'name-and-surname'}>{firstName} {lastName}</span>
                  </h4>
                  <div className="artist-id" onClick={() => navigate(`/user/${uuid}`)}>@{username}</div>
                  <div className="artist-meta-item">
                    <div className="artist-created">{numberOfCreatedNFTS}</div>
                    <div className="artist-meta-type">Created</div>
                  </div>
                </div>
                <div className="artist-follow-btn">
                  <ButtonWrapper>
                    <Button
                      onClick={isFollowed ? deleteSubscription : setSubscription}
                      isValid
                      background={isFollowed ? 'none' : 'green'}
                      fontSize={14}
                    >
                      {isFollowed ? 'Followed' : 'Follow'}
                    </Button>
                  </ButtonWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleUserCard;
