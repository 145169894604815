import React, { useState } from "react";
import "./index.scss";
import styled, {css} from "styled-components";

interface AccordionProps {
  heading: string,
  content: string
}

const AccordionContent = styled.div<{isActive: boolean}>`
  font-size: 18px;
  padding: 20px 30px 30px;
  background: var(--clr-bg-white);
  display: none;
  transition: all .3s linear;
  color: var(--clr-common-body-text);
  
  ${({ isActive }) => isActive && css`
    border-top: 1px solid #e0e2e5;
    display: flex;
  `};
`

const Accordion = (props: AccordionProps) => {
  const {heading, content} = props
  const [isActive, setIsActive] = useState(false);
  return (
    <li className="accordion-item">
      <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
        <span className={'plus'}>{isActive ? "-" : "+"}</span>
        <span>{heading}</span>
      </div>
      <AccordionContent className="accordion-content" isActive={isActive}>{content}</AccordionContent>
    </li>
  );
};

export default Accordion;