import React, {useEffect, useState} from 'react';
import SimpleUserCard from "./SimpleUserCard";
import './index.scss';
import { instance } from '../../api/constants';
import {User} from "../../types/user";
import styled from "styled-components";
import Select from "../common/Select";

const times = ['1 Day', '7 Days', '1 Month']

const SelectsWrapper = styled.div`
  width: 200px;
`
const SimpleUserCardsWrapper = () => {

  const [users, setUsers] = useState<User[] | undefined>(undefined)
  const [time, setTime] = useState<string>("")

  const getAllTopCreators = async () => {
    try {
      const response = await instance.get('/user/list/')
      setUsers(response.data.data)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getAllTopCreators()
  }, [])

  return (
    <section className='top-seller-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12 col-lg-12'>
            <div className="row wow fadeInUp title-container">
              <div className="col-lg-8 col-md-8 col-sm-8">
                <div className="section-title1">
                  <h2 className="section-main-title1">Top Creators</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <SelectsWrapper>
                  <Select
                    label={'Time'}
                    currentValue={time}
                    values={times}
                    onChangeRaw={setTime}
                    isLabel={false}
                  />
                </SelectsWrapper>
              </div>
            </div>
            <div className='row wow fadeInUp'>
              {users && users.map(user => (
                <SimpleUserCard
                  key={user.uuid}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  username={user.username}
                  image={user.avatar ? user.avatar : ""}
                  numberOfCreatedNFTS={20}
                  uuid={user.uuid}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleUserCardsWrapper;
