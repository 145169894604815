import React, {ReactNode} from 'react';
import Header from '../Header'
import Footer from "../Footer";
import './index.scss'
import NavigationPanel from "../NavigationPanel";
interface HeaderProps {
  children: ReactNode[] | ReactNode
}

const Layout = (props: HeaderProps) => {
  const {children} = props;

  return (
    <div>
      <Header/>
      <div className={'main-container'}>
        {window.location.pathname !== '/' && <NavigationPanel />}
        {children}
      </div>
      <Footer/>
    </div>
  );
};

export default Layout;