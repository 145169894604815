import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import Web3 from "web3";
export const injected = new InjectedConnector({
    supportedChainIds: [56, 97, 1, 3, 4, 5, 42, 4002, 11155111]
});

export const walletconnect = new WalletConnectConnector({
    rpc: {
        56: "https://bsc-dataseed.binance.org/",
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
    qrcode: true,
    bridge: "https://bridge.walletconnect.org",
});

export const walletconnectNoQr = new WalletConnectConnector({
    rpc: {
        56: "https://bsc-dataseed.binance.org/",
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
    qrcode: false,
    bridge: "https://bridge.walletconnect.org",
});

export const getLibrary = (provider) => new Web3(provider);
