import React from 'react';
import './index.scss';
import BlackLogo from '../../icons/logo/logo-black.svg'
import {Link} from 'react-router-dom'
import {RouteNames} from "../../router";
const Footer = () => {
  return (
    <footer data-background="" className="footer1-bg">
      <section className="footer-area footer-area1 footer-area1-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer1-widget footer1-widget1 mb-40">
                <div className="footer-logo mb-30">
                  <Link to={RouteNames.CONTACT}>
                    <img src={BlackLogo} alt="logo"/>
                  </Link>
                </div>
                <p className="under-logo-text">
                  The best NFT Marketplace
                </p>
                <div className="social__links footer__social">
                  <ul>
                    <li>
                      <a href="https://twitter.com/RaBitChain"><i className="fab fa-twitter social-icon"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="fab fa-instagram social-icon"></i></a>
                    </li>
                    <li>
                      <a href="https://discord.com/invite/rabitjp"><i className="fab fa-discord social-icon"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-4">
              <div className="footer-widget footer1-widget footer1-widget2 mb-40">
                <div className="footer-widget-title">
                  <h4>Marketplace</h4>
                </div>
                <ul>
                  <li><a href="https://rabitchain.io">Web</a></li>
                  <li><a href="https://t.me/rabit_JP">Tg</a></li>
                  <li><a href="https://medium.com/@rabit.ecosystem">Medium</a></li>
                  <li><a href="#">Link4</a></li>
                  <li><a href="#">Link5</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-4">
              <div className="footer-widget footer1-widget footer1-widget3 mb-40">
                <div className="footer-widget-title">
                  <h4>Links</h4>
                </div>
                <ul>
                  <li><a href="#">Link1</a></li>
                  <li><a href="#">Link2</a></li>
                  <li><a href="#">Link3</a></li>
                  <li><a href="#">Link4</a></li>
                  <li><a href="#">Link5</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-4">
              <div className="footer-widget footer1-widget footer1-widget4 mb-40">
                <div className="footer-widget-title">
                  <h4>Join us</h4>
                </div>
                <ul>
                  <li><a href="https://twitter.com/RaBitChain">Twitter</a></li>
                  <li><a href="#">Instagram</a></li>
                  <li><a href="https://discord.com/invite/rabitjp">Discord</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright-area copyright1-area">
        <div className="copyright1-inner">
          <div className="ow align-items-center justify-content-center copyright-wrapper">
            <div className="col">
              <div className="copyright-text text-center">
                © Copyrighted
                <a href="https://bunny-paradise-world.com" target={'_blank'}> Bunny Paradise World</a>
              </div>
            </div>
            <div className="mode-switch-wrapper my_switcher setting-option">
              <input type="checkbox" className="checkbox" id="chk"/>
              <label className="label" htmlFor="chk">
                <i
                  className="fas fa-moon setColor dark theme__switcher-btn"
                  data-theme="dark"
                ></i>
                <i
                  className="fas fa-sun setColor light theme__switcher-btn"
                  data-theme="light"
                ></i>
                <span className="ball"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
