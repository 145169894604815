import React, {ChangeEvent, InputHTMLAttributes, useState} from 'react';
import styled, {css} from 'styled-components';

interface StyledInputWithLabelProps {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  onChangeRaw: (newValue: string) => void;
  label: string,
  isValid?: boolean,
  incorrectFieldMessage?: string
}

const StyledInputWithLabel = styled.div<{ isNotValid?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    height: 60px;
    width: 100%;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    background: var(--clr-bg-white);
    color: var(--clr-common-heading);
    font-size: 16px;
    padding: 0 20px;
    outline: none;
    box-shadow: none;
    margin-bottom: 30px;
    transition: all .3s linear;

    ${({ isNotValid }) => isNotValid && css`
      border: 1px solid #fd3566;
    `};
  }

  input:focus {
    border-color: var(--clr-bg-white);
    background: var(--clr-bg-white);
    box-shadow: 0 30px 50px rgba(28, 52, 94, .1);
    outline: none
  }

  label {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    display: block;
    line-height: 1;
    margin-bottom: 12px
  }
`

const IncorrectFieldMessage = styled.div<{ isNotValid?: boolean }>`
  position: absolute;
  left: 0;
  bottom: 10px;
  color: #fd3566;
  font-weight: 400;
  font-size: 10px;
  opacity: 0;
  transition: all .3s linear;

  ${({ isNotValid }) => isNotValid && css`
    opacity: 1;
  `};
`

const InputWithLabel = (props: StyledInputWithLabelProps) => {

  const [didUserInput, setDidUserInput] = useState(false)

  const {
    inputProps: {
      placeholder,
      type,
      className,
      value,
      id
    },
    onChangeRaw,
    label,
    isValid,
    incorrectFieldMessage
  } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDidUserInput(true)
    onChangeRaw(e.target.value)
  }

  const shouldDisplayError = didUserInput && !isValid

  return (
    <StyledInputWithLabel isNotValid={shouldDisplayError}>
      <label htmlFor={id}>{label}</label>
      <input
        onChange={handleInputChange}
        type={type}
        placeholder={placeholder}
        className={className}
        id={id}
        value={value}
      />
      <IncorrectFieldMessage isNotValid={shouldDisplayError}>{incorrectFieldMessage}</IncorrectFieldMessage>
    </StyledInputWithLabel>
  );
};

export default InputWithLabel;