import React, {ChangeEvent, TextareaHTMLAttributes} from 'react';
import styled from 'styled-components';

interface StyledTextareaProps {
  textareaProps: TextareaHTMLAttributes<HTMLTextAreaElement>;
  onChangeRaw: (newValue: string) => void;
  label: string
}

const StyledTextarea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    display: block;
    line-height: 1;
    margin-bottom: 12px
  }
  
  textarea {
    height: 150px;
    width: 100%;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    background: var(--clr-bg-white);
    color: var(--clr-common-heading);
    font-size: 16px;
    padding: 15px 20px;
    outline: none;
    box-shadow: none;
    margin-bottom: 30px;
    resize: none
  }

  textarea:focus {
    border-color: var(--clr-bg-white);
    background: var(--clr-bg-white);
    box-shadow: 0px 30px 50px rgba(28, 52, 94, .1);
    outline: none
  }
`

const Textarea = (props: StyledTextareaProps) => {

  const {
    textareaProps: {
      placeholder,
      className,
      value,
      id
    },
    onChangeRaw,
    label
  } = props;

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeRaw(e.target.value)
  }

  return (
    <StyledTextarea>
      <label htmlFor={id}>{label}</label>
      <textarea
        onChange={handleTextareaChange}
        placeholder={placeholder}
        className={className}
        value={value}
        id={id}
      />
    </StyledTextarea>
  );
};

export default Textarea;