import axios from "axios";

export const API_URL = 'https://api.rabitdevelop.online'
export const instance = axios.create({
  baseURL: 'https://api.rabitdevelop.online',
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
  withCredentials: true,
});

export const fileInstance = axios.create({
  baseURL: 'https://api.rabitdevelop.online',
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
  },
  withCredentials: true,
})