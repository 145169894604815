import React, {Provider, useContext, useEffect, useRef, useState} from 'react';
import WhiteLogo from '../../icons/logo/logo-white.svg'
import './index.scss';
import useOnClickOutside from "../../hooks/useOnClickOutside";
import Button from "../common/Button";
import {useNavigate, Link} from "react-router-dom";
import {RouteNames} from "../../router";
import {truncate} from "../../utils/common";
import {useWeb3React} from "@web3-react/core";
import {instance} from "../../api/constants";

interface SidebarProps {
  isActive: boolean,
  setIsSidebarActive: (newValue: boolean) => void
}

const Sidebar = (props: SidebarProps) => {
  const {account} = useWeb3React()
  const {isActive, setIsSidebarActive} = props
  const sidebarRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [userCheck, setUserCheck] = useState(undefined)
  const redirect = (path: string) => {
    setIsSidebarActive(false)
    navigate(path)
  }





  const getUserData = async () => {
    try {
      const response = await instance.get('/user/me')

      console.log(response)
      if(response.status === 200){
        const address = response.data.data.address
        setUserCheck(address.slice(0,10))
        console.log(userCheck)
      }
    } catch (e) {
      console.log(e)
    }
  }
 useEffect(()=>{
   getUserData()
 },[])

  return (
    <div className={`side-info ${isActive ? 'info-open' : ''}`} ref={sidebarRef}>
      <div className="side-info-content">
        <div className="offset-widget offset-logo sidebar-logo">
          <div className="row align-items-center">
            <div className="col-9">
              <a href="index.html">
                <img src={WhiteLogo} alt="Logo"/>
              </a>
            </div>
            <div className="col-3 text-end">
              <button className="side-info-close">
                <i className="fal fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mobile-menu fix mean-container">
          <div className="mean-bar">
            <nav className="mean-nav">
              <ul>
                <li className={'menu-link'}>
                <Link
                    to={RouteNames.HOME}
                    className={"menu-item"}
                    state={{prevRoute: window.location.pathname}}>
                  Home
                </Link>
                </li>
                <li className={'menu-link'}>
                  <Link
                      to={RouteNames.EXPLORE}
                      className={"menu-item"}
                      state={{prevRoute: window.location.pathname}}>
                    Explore
                  </Link>
                </li>
                <Link
                  className={'menu-link'}
                  to={`/${RouteNames.FAQ}`}
                  onClick={() => setIsSidebarActive(false)}
                >
                  FAQ
                </Link>
                <Link
                  className="menu-link mean-last"
                  to={`/${RouteNames.CONTACT}`}
                  onClick={() => setIsSidebarActive(false)}
                >
                  Contact
                </Link>
              </ul>
            </nav>
          </div>
        </div>
        <div className="connect-button">
          <Button
            fontSize={16}
            onClick={() => {
              setIsSidebarActive(false)
              redirect(`/${RouteNames.CONNECT_WALLET}`)
            }}
            isValid
            background={'green'}
          >
            <span className={'wallet-connect-text'}>{userCheck? userCheck : 'Connect Wallet'}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
