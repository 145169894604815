import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';
interface StyledButtonProps {
  children: ReactNode | ReactNode[],
  onClick: () => void,
  isValid: boolean,
  background: 'green' | 'orange' | 'red' | 'blue' | 'none',
  fontSize: number,
  className?:string,
}

const buttonColor: { [key: string]: string } = {
  'orange': '#fcbd11',
  'red': '#fd3566',
  'blue': '#3b99fc'
}

const StyledButton = styled.button<{isValid: boolean, background: 'orange' | 'green' | 'red' | 'blue' | 'none', fontSize: number}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  font-size: ${p => p.fontSize}px;
  font-weight: 600;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  transition: all ease-out .3s;
  color: var(--clr-common-white);
  border: none;
  
  ${({ background }) => background === 'green' && css`
    background-image: linear-gradient(to right, #20d083, #18915d, #20d083);
    background-size: 200% 100%;

    &:hover {
      color: var(--clr-common-white);
      background-position: 100% 100%;
    }
  `};

  ${({ background }) =>( background === 'orange' || background === 'red' || background === 'blue') && css`
    background: ${buttonColor[background]};
    
    &:before {
      background-color: var(--clr-common-white);
      content: "";
      height: 150px;
      left: -75px;
      position: absolute;
      top: -35px;
      transform: rotate(35deg);
      transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 40px;
      opacity: 0;
    }

    &:hover:before {
      left: 120%;
      transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0.25;
    }
  `};

  ${({ background }) => background === 'none' && css`
    color: #636363;
    border: 1px solid #e0e2e5;
    background: #fff;
  `};
  
  ${({ isValid }) => !isValid && css`
    color: #636363;
    background: #86909d;
  `};
`

const Button = (props: StyledButtonProps) => {
  const {children, className, isValid, background, onClick, fontSize} = props;

  return (
    <StyledButton
      isValid={isValid}
      className={className}
      background={background}
      onClick={onClick}
      fontSize={fontSize}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
