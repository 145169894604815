import React, {useEffect, useState} from 'react';
import {API_URL, instance} from "../../api/constants";
import {useParams} from "react-router-dom";
import './index.scss';
import {User} from "../../types/user";
import Loader from "../../components/common/Loader";
import {Nft} from "../../types/nft";
import SimpleNftCard from "../../components/SimpleNftsWrapper/SimpleNftCard";
import Button from "../../components/common/Button";
import setSCRFCookie from "../../api/common";
import {unreformatDate} from "../../utils/common";

const NFTTabs = ['Created', 'Collection', 'Featured Items', 'Total Sold', 'Total Bids']

const UserProfile = () => {
    const params = useParams()

    const [user, setUser] = useState<User | undefined>(undefined)
    const [allLikes, setAllLikes] = useState()
    const [activeTab, setActiveTab] = useState<number>(0)
    const [createdNFTs, setCreatedNFTs] = useState<Nft[] | undefined>(undefined)
    const [subscriptionsCount, setSubscriptionsCount] = useState<number | undefined>(undefined)
    const [isFollowed, setIsFollowed] = useState<Boolean | null>(null)
    const [followers, setFollowers] = useState()
    const [isLike, setIsLike] = useState(false)
    const getCurrentUser = async () => {
        try {
            const response = await instance.get(`/user/${params.uuid}`)
            setUser(response.data.data)
            console.log(user)
        } catch (e) {
            console.log(e)
        }
    }

    const getLike = async () => {
        try {
            const response = await instance.get(`/user/${params.uuid}`)
            setAllLikes(response.data.data.count_nft_likes)
            console.log(allLikes)
            // setAllLikes(response.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const getCreatedNFTs = async () => {
        try {
            const response = await instance.get('/nft/list', {
                params: {
                    user_uuid: user?.uuid,
                    in_blockchain: 1,
                    category:'',
                    name:''

                }
            })
            setCreatedNFTs(response.data.data)
        } catch (e) {
            console.log(e)
        }
    }
    const setSubscription = async () => {
        try {
            const response = await instance.post(`/user/subscribe/${user?.uuid}`)
                .then(()=> setIsFollowed(true))
        } catch (e) {
            console.log(e)
        }
    }

    const deleteSubscription = async () => {
        try {
            await setSCRFCookie()
            const response = await instance.post(`/user/unsubscribe/${user?.uuid}`).then(()=> setIsFollowed(false))

        } catch (e) {
            console.log(e)
        }
    }

    const checkSubscription = async () => {
        try {
            const response = await instance.get(`/user/subscribe/${user?.uuid}`)
            setIsFollowed(response.data.result)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        getLike()
    },[isLike])
    useEffect(() => {
        getLike()
        checkSubscription()
    }, [user])

    const getSubscriptions = async () => {
        try {
            const response = await instance.get(`/user/subscription/count/${user?.uuid}`)
            console.log(response.data)
            setFollowers(response.data.count)
        } catch (e) {
            console.log(e)
        }
    }

    const getSubscriptionsToUser = async () => {
        try {
            const response = await instance.get(`/user/subscription_to_user/count/${user?.uuid}`)
            console.log(response.data)
            setSubscriptionsCount(response.data.count)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getCurrentUser()
    }, [])

    useEffect(() => {
        if (user) {
            getSubscriptions()
            getSubscriptionsToUser()
        }
    }, [user])
    useEffect(()=>{
        getSubscriptionsToUser()
    },[isFollowed])
    useEffect(() => {
        if (user && activeTab === 0) {
            getCreatedNFTs()
        }
    }, [user, activeTab])

    return (
        <section className="creator-details-area pt-0 pb-90">
            {user ?
                <div>
                    <div className="creator-cover-img creator-details-cover-img pos-rel wow fadeInUp">
                        <img
                            src={`${API_URL}/${user.avatar_background}`}
                            alt="cover-img"
                        />
                    </div>
                    <div className="container">
                        <div className='row'>
                            <div className="col-xl-3 col-lg-6 col-md-8">
                                <div className="creator-about mb-40 wow fadeInUp">
                                    <div className="profile-img pos-rel">
                                        <img src={`${API_URL}/${user.avatar}`} alt="img"/>
                                    </div>
                                    <h4 className="artist-name pos-rel">{user.first_name} {user.last_name}</h4>
                                    <div className="artist-id">@{user.username}</div>
                                    <p>{user.description}</p>
                                    <ul>
                                        <li>
                                            <i className="fas fa-map-marker-alt"></i>{user.location}
                                        </li>
                                        <li>
                                            <i className="flaticon-link"></i><a href="#">bit.ly/yte89k6</a>
                                        </li>
                                        <li><i className="flaticon-calendar"></i>Joined March 2010</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-9">
                                <div className="creator-info-bar mb-30 wow fadeInUp">
                                    <div className="artist-meta-info creator-details-meta-info">
                                        <div className="artist-meta-item artist-meta-item-border">
                                            <div className="artist-meta-type">Created</div>
                                            <div className="artist-created">{createdNFTs?.length}</div>
                                        </div>
                                        <div className="artist-meta-item artist-meta-item-border">
                                            <div className="artist-meta-type">Like</div>
                                            <div className="artist-likes">{allLikes}</div>
                                        </div>
                                        <div className="artist-meta-item artist-meta-item-border">
                                            <div className="artist-meta-type">Followers</div>
                                            <div className="artist-follwers">{subscriptionsCount}</div>
                                        </div>
                                        <div className="artist-meta-item">
                                            <div className="artist-meta-type">Followed</div>
                                            <div className="artist-followed">{followers}</div>
                                        </div>
                                    </div>
                                    <div className="creator-details-action">
                                        <div className="artist-follow-btn">
                                            <Button
                                                onClick={isFollowed ? deleteSubscription : setSubscription}
                                                isValid
                                                className="follow-artist"
                                                background={isFollowed ? 'none' : 'green'}
                                                fontSize={14}
                                            >
                                                {isFollowed ? 'Followed' : 'Follow'}
                                            </Button>
                                        </div>
                                        <div className="social__links creator-share">
                                            <i className="flaticon-share"></i>
                                            <ul className="d-none">
                                                <li>
                                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="profile-link-text">
                                            {user?.address}
                                            <button>
                                                <i className="flaticon-copy"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="creator-info-tab wow fadeInUp">
                                    <div className="creator-info-tab-nav mb-30">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                {NFTTabs.map((tabName, index) => (
                                                    <button
                                                        className={`nav-link nft-tab ${activeTab === index ? 'active' : ''}`}
                                                        id="nav-created-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#tab-nav1"
                                                        type="button"
                                                        role="tab"
                                                        aria-selected="true"
                                                        onClick={() => setActiveTab(index)}
                                                        key={tabName}
                                                    >
														<span className="profile-nav-button">
															<span className="artist-meta-item artist-meta-item-border">
																<span className="artist-meta-type">{tabName}</span>
																<span className="artist-created count-of-nft">3</span>
															</span>
														</span>
                                                    </button>
                                                ))}
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="creator-info-tab-contents mb-30">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div
                                                className="tab-pane fade active show"
                                                id="tab-nav1"
                                                role="tabpanel"
                                                aria-labelledby="nav-created-tab"
                                            >
                                                {
                                                    activeTab === 0 && createdNFTs && user &&
                                                    <div className="created-items-wrapper">
                                                        <div className={'row'}>
                                                            {createdNFTs.map(nft => (
                                                                <SimpleNftCard
                                                                    isLikeSet={setIsLike}
                                                                    nftUuid={nft.uuid}
                                                                    isLikeAuthUser={nft.isLikeAuthUser}
                                                                    key={user.uuid + nft.image}
                                                                    owner={nft.owner}
                                                                    collection_address={nft.collection_address}
                                                                    nftPhoto={nft.image}
                                                                    nftName={nft.name}
                                                                    nftPrice={nft.price}
                                                                    countOfLikes={nft.likes}
                                                                    ownerPhoto={user.avatar}
                                                                    ownerUsername={user.username}
                                                                    userUuid={user.uuid}
                                                                    className={'col-xl-4 col-lg-4 col-md-6 col-sm-6'}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loader/>
            }
        </section>
    );
};

export default UserProfile;
