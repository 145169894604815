export const truncate = (str: string): string => {
  return str.length > 0
    ? str.substr(0, 5) + "..." + str.substr(str.length - 5, str.length - 1)
    : str;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

export const reformatDate = (date: string) => {
  const dArr = date.split("-");
  return dArr[2]+ "." +dArr[1]+ "." +dArr[0];
}

export const unreformatDate = (date: string) => {
  const dArr = date.split(".")
  return dArr[2] + "-" + dArr[1] + "-" + dArr[0]
}