import Web3 from 'web3';
import { parseEther } from "ethers/lib/utils";
import { Buffer } from "buffer";
import { ethers } from "ethers";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import {Network, OpenSeaPort} from "opensea-js";
import {Seaport} from "@opensea/seaport-js";

interface typeProps {
    contractNft:string,
    owner:string
}
export const sellNFT = async (contractNft: string, owner: string) => {
    // const {contractNft, owner} = props
    window.Buffer = window.Buffer || Buffer;

     // console.log(provider)
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const seaport = new Seaport(provider);



    const web3Instance = new Web3(window.ethereum);
    const accounts = await web3Instance.eth.getAccounts();
    const offerer = accounts[0];
    const fulfiller = owner;
    const { executeAllActions } = await seaport.createOrder(
        {
            offer: [
                {
                    amount: parseEther("0.00").toString(),
                    // WETH
                    token: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                },
            ],
            consideration: [
                {
                    itemType: ItemType.ERC721,
                    token: contractNft,
                    identifier: "1",
                    recipient: offerer,
                },
            ],
        },
        offerer
    );

    const order = await executeAllActions();

    const { executeAllActions: executeAllFulfillActions } =
        await seaport.fulfillOrder({
            order,
            accountAddress: fulfiller,
        });

    const transaction = executeAllFulfillActions();
};

