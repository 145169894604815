import React, {useEffect, useState} from 'react';
import './index.scss'
import HeartIcon from '../../../icons/heart'
import {API_URL, instance} from "../../../api/constants";
import Button from '../../../components/common/Button'
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {sellNFT} from "../../../hooks/sellNFT";
import {data} from "jquery";
import setSCRFCookie from "../../../api/common";

interface SimpleNftCardProps {
  isLikeAuthUser: boolean,
  nftPhoto: string,
  nftName: string,
  nftPrice: string,
  countOfLikes: number,
  ownerPhoto: string,
  ownerUsername: string;
  userUuid: string,
  className?: string,
  collection_address:string,
  owner:string,
  nftUuid:string,
  isLikeSet?: React.Dispatch<React.SetStateAction<boolean>>

}

const SimpleNftCardDefautlProps = {
  className: 'col-xl-3 col-lg-4 col-md-6 col-sm-6'
}

const ButtonWrapper = styled.div`
  width: 120px;
`
const SimpleNftCard = (props: SimpleNftCardProps) => {
  const { isLikeSet, nftUuid, isLikeAuthUser, nftPhoto, nftName, nftPrice, countOfLikes, ownerPhoto, ownerUsername, userUuid, className, collection_address, owner } = props
  const [isLike, setIsLike] = useState(false)
  const setLike= async () => {
    try {
      const data = {
        nft_uuid: nftUuid
      }
      await setSCRFCookie()
      const response = await instance.post(`/nft/like`, data)
      setIsLike(true)
      if (isLikeSet) {
        isLikeSet(true)
      }
      console.log(response)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(()=>{
    setIsLike(isLikeAuthUser)
  },[])


  const unlike= async () => {
    try {
      const data = {
        nft_uuid: nftUuid
      }
      await setSCRFCookie()
      const response = await instance.post(`nft/unlike`, data)
      setIsLike(false)
      if (isLikeSet) {
        isLikeSet(false)
      }
      console.log(response)
    } catch (e) {
      console.log(e)
    }
  }
  const navigate = useNavigate()

  return (
    <div className={className}>
      <div className="art-item-single mb-30">
        <div className="art-item-wraper">
          <div className="art-item-inner">
            <div className="art-item-img pos-rel">
              <div className="art-action">
                <button className="art-action-like" onClick={isLike? ()=>unlike() :()=>setLike()}>
                  <HeartIcon like={isLike}/>
                </button>
                <div className="art-action-like-count">{countOfLikes}</div>
                <div className="art-action-collection">
                  <i className="flaticon-plus-sign"></i>
                </div>
              </div>
              <div className="place-bid">
               <ButtonWrapper>
                 <Button onClick={() => {sellNFT(collection_address, owner)}} isValid background={'green'} fontSize={14}>
                   Place bid
                 </Button>
               </ButtonWrapper>
              </div>
              <img src={`${API_URL}/${nftPhoto}`} alt="art-img" className={'home-art-image'} />
            </div>
            <div className="art-item-content pos-rel">
              <div className="artist">
                <div className="profile-img pos-rel">
                  <img src={`${API_URL}/${ownerPhoto}`} alt="profile-img" />
                  <div className="profile-verification verified">
                    <i className="fas fa-check"></i>
                  </div>
                </div>
                <div
                  className="artist-username"
                  onClick={() => navigate(`/user/${userUuid}`)}
                >@{ownerUsername}</div>
              </div>
              <h4 className="art-name">
                <span className="artwork-name">{nftName}</span>
              </h4>
              <div className="art-meta-info">
                <div className="art-meta-item">
                  <div className="art-meta-type">Current Bid</div>
                  <div className="art-price">{nftPrice} ETH</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleNftCard.defaultProps = SimpleNftCardDefautlProps
export default SimpleNftCard;
