import React, {useEffect, useState} from 'react';
import './index.scss';
import Accordion from "../../components/common/Accordion";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../router";
import {instance} from "../../api/constants";
import {FaqItem} from "../../types/faq";
import {Link} from 'react-router-dom'
import Loader from "../../components/common/Loader";
const FAQ = () => {

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("General")
  const [allQuestion, setAllQuestions] = useState<FaqItem[] | undefined>(undefined)
  const [currentActiveBlock, setCurrentActiveBlock] = useState<FaqItem | undefined>(undefined)

  const getQuestions = async () => {
    try {
      const response = await instance.get('/faq')
      setAllQuestions(response.data.data)
    } catch (e) {
      console.log(e)
    }
  }
  const handleQuestionsChange = () => {
    const currentActiveQuestions = allQuestion?.filter(question => question.caption === activeTab)[0]
    if (currentActiveQuestions) {
      setCurrentActiveBlock(currentActiveQuestions)
    }
  }

  useEffect(() => {
    getQuestions()
  }, [])

  useEffect(() => {
    if (allQuestion) {
      handleQuestionsChange()
    }
  }, [activeTab, allQuestion])

  return (
    <div>
      <section className="about-info-area">
        {allQuestion?.length ?
          <div className={'container'}>
            <div className="row">
              <div className="col-lg-8">
                <div className="faq-tab-contents mb-0 wow fadeInUp">
                  <div className="tab-content">
                    <div>
                      <div className="about-info-wrapper mb-30">
                        <div className="accordion accordion-general">
                          {currentActiveBlock?.items.map(({caption, text}) => (
                            <div className="accordion-item">
                              <Accordion heading={caption} content={text} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="page-sidebar">
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="sidebar-tab-nav sidebar-widget-single mb-30 wow fadeInUp">
                        <nav>
                          <div className="nav nav-tabs">
                            {allQuestion?.map(question => (
                              <button
                                className={`nav-link ${question.caption === activeTab && 'active'}`}
                                id="nav-tab1"
                                data-bs-toggle="tab"
                                data-bs-target="#tab-nav1"
                                type="button"
                                role="tab"
                                aria-selected="true"
                                onClick={() => setActiveTab(question.caption)}
                              >
                            <span className="sidebar-nav-link">
                              <i className="flaticon-home"></i>{question.caption}
                            </span>
                              </button>
                            ))}
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq-note text-center wow fadeInUp">
              <div className="note">
                These information are not helping you?
                <Link
                  className="text-btn"
                  to={`/${RouteNames.CONTACT}`}
                  state={{prevRoute: window.location.pathname}}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
          :
          <Loader />
        }
      </section>
    </div>
  );
};

export default FAQ;