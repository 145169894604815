import React, {Provider, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import MetamaskIcon from '../../icons/wallets/wallet2.png';
import TrustWalletIcon from '../../icons/wallets/wallet1.png';
import './index.scss';
import Button from "../../components/common/Button";
import {RouteNames} from "../../router";
import {instance} from "../../api/constants";
import setSCRFCookie from "../../api/common";
import {Link, redirect} from 'react-router-dom'
import {walletconnect, injected, switchNetwork} from '../../wallet'
import NotificationContext from "../../context/NotificationContext";
import styled from "styled-components";
import {useWeb3React} from "@web3-react/core";
import {a} from "react-spring";
import {web3} from "@project-serum/anchor";
import Web3 from "web3";

const ButtonWrapper = styled.div`
  width: 180px;
`

const WalletConnect = () => {
    let {account, activate, deactivate} = useWeb3React();
    const {displayNotification} = useContext(NotificationContext)
    const [checkToken, setCheckToken] = useState(false)

    const web3Instance = new Web3(window.ethereum);

    async function connectToMetaMask() {
        const accounts = await web3Instance.eth.getAccounts();
        const offerer = accounts[0];
        // Проверка доступности MetaMask
        if (typeof window.ethereum === 'undefined') {
            console.log('MetaMask не найден');
            displayNotification(
                'error',
                'metamask not found',
                'Download to MetaMask!'
            )
            return;
        }

        try {
            if(offerer){
                console.log(offerer)
                login()
            }else {
                await window.ethereum.enable();
                login()
            }


        } catch (error) {
            console.log(222)
            displayNotification(
                'default',
                'Success',
                'Wallet connected!'
            )

        }
    }


    const login = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });

        await setSCRFCookie()

        try {
            const data = {
                address: accounts[0],
                address_type: "metamask",
                remember: true,
                dev_mode: false
            };
                const response = await instance.post('/login', data)
                if (response.status === 200 || response.status === 201) {
                    console.log(222)
                     window.location.href = '/';
                    displayNotification(
                        'default',
                        'Success',
                        'Wallet connected!'
                    )
                }


        } catch (e) {
            console.log(11111)
            displayNotification(
                'error',
                'Connection error',
                'Something went wrong!'
            )
            await deactivate()
        }
    }




    function checkMetaMaskConnection(): boolean {
        const provider: Provider<any> | undefined = (window as any).ethereum;

        return !!(provider && 'isConnected' in provider && typeof provider.isConnected === 'function');


    }

    // useLayoutEffect(() => {
    //     if (checkMetaMaskConnection()){
    //         connectToMetaMask()
    //     }
    //     return
    // },)




    return (
        <section className="connect-wallet-area">
            <div className="container">
                <div className="connect-wallet-wrapper">
                    <div className="row wallets-wrapper">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="box-card-single box-card-wallet mb-40 wow fadeInUp">
                                <div className="box-card-inner text-center">
                                    <div className="box-card-icon">
                                        <img
                                            src={TrustWalletIcon}
                                            alt="wallet-img"
                                        />
                                    </div>
                                    <h4 className="box-card-title">Wallet Connect</h4>
                                    <p>
                                        Sale your single item to increase your currencies that
                                        generated by crypto currency.
                                    </p>
                                    <ButtonWrapper>
                                        <Button
                                            fontSize={16}
                                            onClick={() => activate(walletconnect)}
                                            isValid
                                            background={'blue'}
                                        >
                                            <span>Connect</span>
                                        </Button>
                                    </ButtonWrapper>

                                    {/*<ButtonWrapper>*/}
                                    {/*  <Button*/}
                                    {/*    fontSize={16}*/}
                                    {/*    onClick={() => callContractMethod()}*/}
                                    {/*    isValid*/}
                                    {/*    background={'blue'}*/}
                                    {/*  >*/}
                                    {/*    <span>Connect</span>*/}
                                    {/*  </Button>*/}
                                    {/*</ButtonWrapper>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div
                                className="box-card-single box-card-wallet wallet-metamask mb-40 wow fadeInUp"
                            >
                                <div className="box-card-inner text-center">
                                    <div className="box-card-icon">
                                        <img
                                            src={MetamaskIcon}
                                            alt="wallet-img"
                                        />
                                    </div>
                                    <h4 className="box-card-title">Metamask</h4>
                                    <p>
                                        Sale your single item to increase your currencies that
                                        generated by crypto currency.
                                    </p>
                                    <ButtonWrapper>
                                        <Button
                                            fontSize={16}
                                            onClick={() => connectToMetaMask()}
                                            isValid
                                            background={'orange'}
                                        >
                                            <span>Connect</span>
                                        </Button>
                                    </ButtonWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wallet-wrapper-note text-center wow fadeInUp">
                        <div className="note">
                            Need help to collect wallet?
                            <Link
                                className="text-btn"
                                to={`/${RouteNames.CONTACT}`}
                                state={{prevRoute: window.location.pathname}}
                            > Contact Support</Link>or
                            Lean how to collect wallet visiting to our
                            <Link
                                className="text-btn"
                                to={`/${RouteNames.FAQ}`}
                                state={{prevRoute: window.location.pathname}}
                            > FAQ page</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletConnect;
