import React, {useState, ReactNode, useEffect} from 'react';
import './App.css';
import Layout from "./components/Layout";
import AppRouter from "./router/AppRouter";
import NotificationContext from "./context/NotificationContext";
import Notification from "./components/common/Notification";
import {injected} from "./wallet";
import { useWeb3React } from '@web3-react/core';
import {instance} from "./api/constants";
import {User} from "./types/user";
import {unreformatDate} from "./utils/common";

function App() {
  const {active, activate} = useWeb3React();
  const [userCheck, setUserCheck] = useState(null)
  const [shouldDisplayNotification, setShouldDisplayNotification] = useState<boolean>(false);
  const [notificationTitle, setNotificationTitle] = useState<string>('')
  const [notificationSubtitle, setNotificationSubtitle] = useState<string>('')
  const [notificationIcon, setNotificationIcon] = useState<ReactNode>(null)
  const [notificationType, setNotificationType]  = useState<'error' | 'default'>('default')
  const displayNotification = (type: 'error' | 'default', title: string, subtitle: string, icon?: ReactNode) => {
    setNotificationIcon(icon)
    setNotificationTitle(title)
    setNotificationSubtitle(subtitle)
    setShouldDisplayNotification(true);
    setNotificationType(type)

    setTimeout(() => {
      setShouldDisplayNotification(false);
    }, 2500);
  };

  const getUserData = async () => {
    try {
      const response = await instance.get('/user/me')

      console.log(response)
      if(response.status === 200){
        setUserCheck(response.data.data.address)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(()=>{
    getUserData()
    console.log(222222222)
  },[])
  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized && !active && userCheck) {
        activate(injected);
      }
    });
  }, [activate]);

  return (
    <NotificationContext.Provider value={{displayNotification}}>
      <Layout>
        <Notification
          type={notificationType}
          shouldDisplay={shouldDisplayNotification}
          title={notificationTitle}
          icon={notificationIcon}
          subtitle={notificationSubtitle}
        />
        <AppRouter/>
      </Layout>
    </NotificationContext.Provider>
  );
}

export default App;
