import React, {useEffect, useState} from 'react';
import {useLocation, Link} from "react-router-dom";
import Arrow from '../../icons/arrow'
import './index.scss'

const NavigationPanel = () => {
  const location = useLocation()

  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    setCurrentPath(window.location.pathname.slice(1, window.location.pathname.length).toUpperCase())
  }, [window.location.pathname, location])

  return (
    <div>
      <section className="page-title-area">
        <div className="container">
          <div className="row wow fadeInUp">
            <div className="col-lg-12">
              <div className="page-title">
                <h2 className="breadcrumb-title mb-10">
                  {currentPath}
                </h2>
                {location.state && location.state.prevRoute &&
                    <div className="breadcrumb-menu">
                        <nav className="breadcrumb-trail breadcrumbs">
                            <ul className="trail-items">
                                <li className="trail-item trail-begin">
                                    <Link
                                        to={location.state?.prevRoute}
                                        state={{prevRoute: window.location.pathname}}
                                    >
                                      {location.state?.prevRoute.slice(1, location.state?.prevRoute.length).toUpperCase()}
                                    </Link>
                                </li>
                                <Arrow />
                                <li className="trail-item trail-end">
                                    <span>{currentPath}</span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NavigationPanel;