import React, {useContext, useEffect, useState} from 'react';
import Button from "../../components/common/Button";
import InputWithLabel from "../../components/common/InputWithLabel";
import Textarea from "../../components/common/Textarea";
import useValidatedState, {validationFuncs} from "../../hooks/useValidatedState";
import Select from "../../components/common/Select";
import './index.scss';
import NotificationContext from "../../context/NotificationContext";
import {scrollToTop} from "../../utils/common";
import {instance} from "../../api/constants";
import {ContactIsUserCan} from "../../types/contact";
import setSCRFCookie from "../../api/common";

const Contact = () => {

  const {displayNotification} = useContext(NotificationContext)

  const [[email, setEmail], isEmailValid] = useValidatedState("" as string, validationFuncs.isEmail)
  const [[name, setName], isNameValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[phone, setPhone], isPhoneValid] = useValidatedState("" as string, validationFuncs.hasLetters)
  const [[message, setMessage], isMessageValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[subject, setSubject], isSubjectValid] = useValidatedState("" as string, validationFuncs.hasValue)

  const [isUserCanSend, setIsUserCanSend] = useState<ContactIsUserCan | undefined>(undefined)

  const checkIsUserCanSend = async () => {
    try {
      const response = await instance.get('/forms/contact')
      setIsUserCanSend(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const sendContactData = async () => {
    scrollToTop()
    if (!(isUserCanSend && isUserCanSend.is_can)) {
      displayNotification(
        'error',
        'Error',
        'Number of attempts exceeded'
      )
      return
    }

    if (!isFormValid) {
      displayNotification(
        'error',
        'Invalid form',
        'All fields should be complete'
      )
      return
    }

    try {

      const contactFormData = {
        name,
        phone,
        subject,
        message,
        email
      };

      await setSCRFCookie()
      const response = await instance.post('/forms/contact', contactFormData)
      if (response.status === 200) {
        displayNotification(
          'default',
          'Success',
          'The application sent!'
        )
      }
    } catch (e) {
      displayNotification(
        'error',
        'Error',
        'Something went wrong!'
      )
      console.log(e)
    } finally {
      setEmail('')
      setName('')
      setPhone('')
      setMessage('')
      setSubject('')
      checkIsUserCanSend()
    }
  }

  const isFormValid =
    isMessageValid &&
    isEmailValid &&
    isNameValid &&
    isPhoneValid &&
    isSubjectValid

  useEffect(() => {
    checkIsUserCanSend()
  }, [])

  return (
    <section className="contact-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="contact-wrapper pos-rel mb-40 wow fadeInUp">
              <div className="contact-inner">
                <div className="contact-content">
                  <h4>Get in Touch</h4>
                  <p className="contact-subtitle">
                    Please feel free to contact with us for any kinds of
                    inquiries and information. Our support team always
                    available to help the clients.
                  </p>
                  <div className="contact-form">
                    <div className="row">
                      <div className="col-md-6">
                        <InputWithLabel
                          inputProps={{
                            placeholder: 'Your Name',
                            type: 'text',
                            id: 'name',
                            value: name
                          }}
                          onChangeRaw={setName}
                          label={'Your Name'}
                          isValid
                        />
                      </div>
                      <div className="col-md-6">
                        <InputWithLabel
                          inputProps={{
                            placeholder: 'Email',
                            type: 'email',
                            id: 'email',
                            value: email
                          }}
                          onChangeRaw={setEmail}
                          label={'Email'}
                          incorrectFieldMessage={'Incorrect email'}
                          isValid={isEmailValid}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputWithLabel
                          inputProps={{
                            placeholder: 'Phone',
                            type: 'phone',
                            id: 'phone',
                            value: phone
                          }}
                          onChangeRaw={setPhone}
                          label={'Phone'}
                          isValid={isPhoneValid}
                          incorrectFieldMessage={'Only signs and numbers are allowed'}
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          currentValue={subject}
                          label={'Subject'}
                          values={['one', 'two', 'free']}
                          onChangeRaw={setSubject}
                        />
                      </div>
                      <div className="col-md-12">
                        <Textarea
                          textareaProps={{
                            placeholder: 'Write a message',
                            id: 'message',
                            value: message
                          }}
                          onChangeRaw={setMessage}
                          label={'Message'}
                        />
                      </div>
                    </div>
                    <div className="contact-btn">
                      <div className="note">
                        By check this box I agree with
                        <span className="text-btn">Terms & Conditions</span>
                      </div>
                      <Button
                        fontSize={16}
                        background={'green'}
                        onClick={sendContactData}
                        isValid={Boolean(isFormValid && isUserCanSend && isUserCanSend.is_can)}
                      >
                        <span>Submit</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;