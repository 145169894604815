import React from 'react';
import './index.scss'
import Button from '../common/Button'
import styled from 'styled-components'
import {RouteNames} from "../../router";
import {Link} from "react-router-dom";

const ButtonWrapper = styled.div`
  width: 125px;
`
const SingleBannerHome = () => {
  return (
    <div className="single-banner single-banner-2 banner-670 d-flex align-items-center pos-rel">
      <div className="container pos-rel">
        <div className="row align-items-center justify-content-center">
          <div className="col-xxl-8 col-lg-10">
            <div className="banner-content banner-content2 banner-content2-1 pt-120">
              <h1 data-animation="fadeInUp" data-delay=".3s" className="mb-25 font-prata">
                <span className='nfts'>NFTs</span> Market
              </h1>
              <p data-animation="fadeInUp" data-delay=".5s" className="mb-40">
                The best NFT Marketplace
              </p>
              <div className="banner-button" data-animation="fadeInUp" data-delay=".7s">
                <ButtonWrapper>
                  <Link
                      to={RouteNames.EXPLORE}
                      className={"menu-item"}
                      state={{prevRoute: window.location.pathname}}>
                    <Button fontSize={16} onClick={() => {}} isValid background={'green'}>
                      Explore
                    </Button>
                  </Link>
                </ButtonWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBannerHome;
