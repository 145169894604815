import React, {useEffect, useState} from 'react';
import SimpleNftCard from "./SimpleNftCard";
import './index.scss';
import {instance} from "../../api/constants";
import {Nft} from "../../types/nft";
import Select from "../common/Select";
import {sellNFT} from "../../hooks/sellNFT";
import setSCRFCookie from "../../api/common";

const categories = ['Illustration', 'Video', 'Cartoon', 'Photography']
const prices = ['High', 'Low']

const SimpleNftWrapper = () => {

  const [nfts, setNfts] = useState<Nft[] | undefined>(undefined)

  const [filteredNfts, setFilteredNfts] = useState<Nft[] | undefined>(undefined)
  const [category, setCategory] = useState<string>("")
  const [price, setPrice] = useState<string>("")

  const getAllNfts = async () => {
    console.log(category)
    try {
      const params = { // Параметры запроса
        user_uuid: '',
        in_blockchain: '1',
        category:category
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await instance.get(`/nft/list/?${queryString}`)
      return setNfts(response.data.data)

      // console.log(response)
    } catch (e) {
      console.log(e)
    }
  }



  useEffect(() => {
    getAllNfts()
    console.log(nfts, 111111)
  }, [category])


  useEffect(() => {
    setFilteredNfts(nfts)
  }, [nfts?.length])

  return (
    <section className="artworks-area artworks-area-bg">
      <div className="container">
        <div className="row wow fadeInUp title-container">
          <div className="col-lg-4">
            <div className="section-title1">
              <h2 className="section-main-title1">Explore Artworks</h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="selects-filters-wrapper">
              <Select
                currentValue={category}
                label={'Category'}
                values={categories}
                onChangeRaw={setCategory}
                isLabel={false}
              />
              <Select
                currentValue={price}
                label={'Price'}
                values={prices}
                onChangeRaw={setPrice}
                isLabel={false}
              />
            </div>
          </div>
        </div>
        <div className="row wow fadeInUp">
          {filteredNfts && filteredNfts.length && filteredNfts.map((nft, index) => (
            <SimpleNftCard
              key={index}
              isLikeAuthUser={nft.isLikeAuthUser}
              owner={nft.owner}
              collection_address={nft.collection_address}
              nftPhoto={nft.image}
              nftName={nft.name}
              nftPrice={nft.price}
              nftUuid={nft.uuid}
              countOfLikes={nft.likes}
              ownerPhoto={nft.user && nft.user.avatar ? nft.user.avatar : ''}
              ownerUsername={nft.user && nft.user.username ? nft.user.username : ''}
              userUuid={nft.user && nft.user.uuid ?  nft.user.uuid : ''}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SimpleNftWrapper;
