interface propsHear{
    like?:boolean
}

export default (props :propsHear) => {
    const {like = false} = props
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8702 1.48056C12.1371 0.747472 11.1665 0.346795 10.1307 0.346795C9.09489 0.346795 8.12139 0.75044 7.3883 1.48353L7.00543 1.8664L6.61662 1.4776C5.88353 0.744504 4.90707 0.337891 3.87124 0.337891C2.83838 0.337891 1.86489 0.741536 1.13476 1.47166C0.40167 2.20475 -0.00197517 3.17825 0.000992814 4.21407C0.000992814 5.2499 0.407606 6.22043 1.1407 6.95352L6.71457 12.5274C6.79173 12.6046 6.89561 12.6461 6.99652 12.6461C7.09744 12.6461 7.20132 12.6075 7.27848 12.5304L12.8642 6.96539C13.5973 6.2323 14.001 5.2588 14.001 4.22298C14.0039 3.18715 13.6033 2.21365 12.8702 1.48056ZM12.3003 6.39851L6.99652 11.6815L1.70461 6.3896C1.12289 5.80788 0.802348 5.03621 0.802348 4.21407C0.802348 3.39194 1.11992 2.62027 1.70165 2.04151C2.2804 1.46276 3.05208 1.14221 3.87124 1.14221C4.69337 1.14221 5.46801 1.46276 6.04974 2.04448L6.7205 2.71524C6.87781 2.87255 7.13008 2.87255 7.28739 2.71524L7.95221 2.05042C8.53394 1.46869 9.30858 1.14815 10.1277 1.14815C10.9469 1.14815 11.7186 1.46869 12.3003 2.04745C12.882 2.62917 13.1996 3.40085 13.1996 4.22298C13.2026 5.04511 12.882 5.81678 12.3003 6.39851Z"
        fill={like ? "red" :"white"}/>
    </svg>
  )
}
