import React, {useContext, useEffect, useState} from 'react';
import './index.scss';
import InputWithLabel from "../../components/common/InputWithLabel";
import Select from "../../components/common/Select";
import Textarea from "../../components/common/Textarea";
import Button from "../../components/common/Button";
import useValidatedState, {validationFuncs, validationDateFuncs} from "../../hooks/useValidatedState";
import {useDropzone} from "react-dropzone";
import NotificationContext from "../../context/NotificationContext";
import setSCRFCookie from "../../api/common";
import {instance, fileInstance, API_URL} from "../../api/constants";
import {reformatDate, scrollToTop} from "../../utils/common";
import styled from "styled-components";
import Web3 from "web3";
import contractAbi from "../../utils/abi.json";
import Loader from "../../components/common/Loader";


declare global {
  interface Window {
    ethereum?: any;
  }
}

const mockPrice: { title: string, subtitle: string, value: string }[] = [
  {
    title: 'Fixes',
    subtitle: 'By select this option you can set your item price fixed.',
    value: 'fixes'
  },
  {
    title: 'Open for Bid',
    subtitle: 'By select this option you can set your item price fixed.',
    value: 'bid'
  },
  {
    title: 'Auction',
    subtitle: 'By select this option you can set your item price fixed.',
    value: 'auction'
  }
]

const ButtonWrapper = styled.div`
  width: 180px;
`

const UploadArtwork = () => {
  const {displayNotification} = useContext(NotificationContext)

  const [[auctionTimeStart, setAuctionTimeStart], isAuctionTimeStartValid] = useValidatedState("" as string, validationDateFuncs.dateIsGreaterOrEqualThanToday)
  const [[auctionTimeEnd, setAuctionTimeEnd], isAuctionTimeEndValid] = useValidatedState("" as string, validationDateFuncs.dateIsGreaterOrEqualThanToday)
  const [[artworkName, setArtworkName], isArtworkNameValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[category, setCategory], isCategoryValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[price, setPrice], isPriceValid] = useValidatedState("" as string, validationFuncs.greaterThanZero)
  const [[currency, setCurrency], isCurrencyValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[royalty, setRoyalty], isRoyaltyValid] = useValidatedState("" as string, validationFuncs.hasValue)
  const [[artworkDescription, setArtworkDescription], isArtworkDescriptionValid] = useValidatedState("" as string, validationFuncs.hasValue)

  const [typeOfPrice, setTypeOfPrice] = useState(mockPrice)
  const [currentTypeOfPrice, setCurrentTypeOfPrice] = useState<{ title: string, subtitle: string, value: string }>(mockPrice[0])
  const [activeTab, setActiveTab] = useState<number>(0)

  const [fileName, setFileName] = useState<string | null>(null)
  const [fileUrl, setFileUrl] = useState<string | null>(null)

  const [transactionHash, setTransactionHash] = useState<string | null>('')

  const [load, setLoad] =useState(false)

  const onDropHandle = async (acceptedFiles: File[]) => {
    try {
      if (acceptedFiles[0]) {
        const file = new FormData()
        file.append('nft', acceptedFiles[0])
        await setSCRFCookie()
        const response = await fileInstance.post('/file/nft', file)
        if (response.status === 200) {
          console.log(22222902132222)
          setFileUrl(response.data.path)
          setFileName(acceptedFiles[0].name)
        }
      }
    } catch (error) {
      displayNotification(
        'error',
        'File error',
        'Something went wrong'
      )
    }
  }



  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: onDropHandle})

  const callContractMethod = async () =>{
    setLoad(true)
    const web3 = new Web3(window.ethereum);
    const contractAddress = '0xE95BC6614Be16b4a053614b45a54fDEcb99605Fd'; // адрес смарт-контракта
    // @ts-ignore
    const contract = new web3.eth.Contract(contractAbi, contractAddress);

    const accounts = await web3.eth.getAccounts();
    const result = await contract.methods.deploy(accounts[0], fileUrl, artworkName, currency).send({
      from: accounts[0],
    });
    // const result = await contract.methods.sell
    if(result.status){
      setTransactionHash(result.transactionHash);

    }

  }

  useEffect(()=>{
    if(transactionHash){
      handleUploadArtwork()
    }
  },[transactionHash])

  useEffect(()=>{
    if(load){
      document.body.style.overflow='hidden'
    } else document.body.style.overflow='inherit'
  },[load])

  const handleUploadArtwork = async () => {
    scrollToTop()
    if (!isNftDataValid) {
      displayNotification(
        'error',
        'Invalid form',
        'All fields should be complete'
      )
      return
    }

    try {
      // callContractMethod(fileUrl, artworkName, currency, setTransactionHash)

      const reformatDateStart = reformatDate(auctionTimeStart)
      const reformatDateEnd = reformatDate(auctionTimeEnd)

      const nftData = {
        transactionHash: transactionHash,
        name: artworkName,
        category: category,
        type: currentTypeOfPrice.value,
        price: price,
        currency: currency,
        royalty: royalty,
        description: artworkDescription,
        image: fileUrl,
        start_at: reformatDateStart,
        end_at: reformatDateEnd,
      }

      await setSCRFCookie()

      const response = await instance.post('/nft/create', nftData)
      console.log(response)
      if (response.status === 200) {
        setLoad(false)
        displayNotification(
          'default',
          'Success',
          'NFT successfully created'
        )
        setTransactionHash('')
        setArtworkName('')
        setCategory('')
        setPrice('')
        setCurrency('')
        setRoyalty('')
        setArtworkDescription('')
        setAuctionTimeStart('')
        setAuctionTimeEnd('')
        setFileUrl(null)
        setFileName(null)
      }

    } catch (e) {
      console.log(e)
      setLoad(false)
      displayNotification(
        'error',
        'Create NFT error',
        'Something went wrong'
      )
    }
  }

  const resetFiles = () => {
    setFileName(null)
    setFileUrl(null)
    setTransactionHash(null)
  }

  const isNftDataValid =
    isArtworkDescriptionValid &&
    isAuctionTimeEndValid &&
    isAuctionTimeStartValid &&
    isArtworkNameValid &&
    isCategoryValid &&
    isPriceValid &&
    isCurrencyValid &&
    isRoyaltyValid &&
    Boolean(fileName) &&
    Boolean(fileUrl)

  return (
    <div className="upload-area upload-wrapper pt-130 pb-90" data-background="">
      <div className="loader" style={load ? {} : {display: 'none'}}>
      <Loader/>
      </div>
      <div className="container">
        <div className="upload-wrapper mb-10">
          <div className={'upload-form'}>
            <div className="row">
              <div className="col-lg-8">
                <div className="row wow fadeInUp">
                  <div className="col-md-6">
                    <InputWithLabel
                      inputProps={{
                        placeholder: 'Artwork name',
                        id: 'art-name',
                        type: 'text',
                        value: artworkName
                      }}
                      isValid={isArtworkNameValid}
                      onChangeRaw={setArtworkName}
                      label={'Artwork name'}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      currentValue={category}
                      label={'Category'}
                      values={['Illustration', 'Video', 'Cartoon', 'Photography']}
                      onChangeRaw={setCategory}
                    />
                  </div>
                  {typeOfPrice.map((type, index) => (
                    <div className="col-md-4" onClick={() => {
                      setCurrentTypeOfPrice(type)
                      setActiveTab(index)
                    }}>
                      <div className={`sale-categoty-box ${index === activeTab ? 'active' : ''}`}>
                        <div className="sale-category-box-title">
                          <i className="flaticon-coin"></i>{type.title}
                        </div>
                        <p>
                          {type.subtitle}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-6">
                    <InputWithLabel
                      inputProps={{
                        placeholder: 'Price',
                        id: 'art-price',
                        type: 'number',
                        value: price
                      }}
                      isValid={isPriceValid}
                      onChangeRaw={setPrice}
                      label={'Price'}
                      incorrectFieldMessage={'Price should be greater than 0'}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputWithLabel
                      inputProps={{
                        placeholder: 'Date',
                        id: 'art-duration-start',
                        type: 'date',
                        value: auctionTimeStart
                      }}
                      onChangeRaw={setAuctionTimeStart}
                      label={'Auction Time Start'}
                      isValid={isAuctionTimeStartValid}
                      incorrectFieldMessage={'Date should be greater than today'}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputWithLabel
                      inputProps={{
                        placeholder: 'Date',
                        id: 'art-duration-end',
                        type: 'date',
                        value: auctionTimeEnd
                      }}
                      onChangeRaw={setAuctionTimeEnd}
                      label={'Auction Time End'}
                      isValid={isAuctionTimeEndValid}
                      incorrectFieldMessage={'Date should be greater than today'}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      currentValue={currency}
                      label={'Currency'}
                      values={['USD', 'EUR']}
                      onChangeRaw={setCurrency}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      currentValue={royalty}
                      label={'Royalty'}
                      values={['10%', '15%', '20%', '30%']}
                      onChangeRaw={setRoyalty}
                    />
                  </div>
                  <div className="col-md-12">
                    <Textarea
                      textareaProps={{
                        placeholder: 'Write Description',
                        id: 'description',
                        value: artworkDescription
                      }}
                      onChangeRaw={setArtworkDescription}
                      label={'Artwork description'}
                    />
                  </div>
                </div>
                <div className="upload-btn wow fadeInUp">
                  <ButtonWrapper>
                    <Button
                      fontSize={16}
                      onClick={callContractMethod}
                      isValid={isNftDataValid}
                      background={'green'}
                    >
                      <span>Upload Now</span>
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      fontSize={16}
                      onClick={() => {
                      }}
                      isValid
                      background={'orange'}>
                      <span>Preview</span>
                    </Button>
                  </ButtonWrapper>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-sm-8">
                    <div className="browse-file-wrapper mb-30 wow fadeInUp" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="browse-file-text">
                        {
                          isDragActive ?
                            'Drop your artwork here...'
                            :
                            'Drag and drop file here, or click to select file'
                        }
                      </div>
                      <div className="browse-file-note">
                        Allowed all format | Max 1 MB
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 col-sm-8">
                    <div className="uploaded-file-wrapper mb-30 wow fadeInUp">
                      {fileName ?
                        <>
                          <div className="uploaded-file-text">Uploaded File</div>
                          <div className="uploaded-files">
                            <div className="uploaded-file">
                              <div>{fileName}</div>
                              {fileName && <img src={`${API_URL}/${fileUrl}`} alt="artwork"/>}
                            </div>
                          </div>
                          <div className="uploaded-file-btn">
                            <button id="upload-reset-btn" className="text-btn" onClick={resetFiles}>
                              Reset All
                            </button>
                          </div>
                        </>
                        :
                        <div className="uploaded-file-text">No Uploaded Files</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadArtwork;
