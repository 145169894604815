import React, {useEffect, useRef, useState} from 'react';
import {Nft} from "../../types/nft";
import {instance} from "../../api/constants";
import Select from "../../components/common/Select";
import SearchInput from "../../components/common/SearchInput";
import './index.scss'
import SearchIcon from "../../icons/searchIcon";
import SimpleNftCard from "../../components/SimpleNftsWrapper/SimpleNftCard";
import '../../sass/_animate.min.scss'
import '../../sass/_swiper-bundle.scss'
import '../../sass/_bootstrap.min.scss'


const categories = ['Illustration', 'Video', 'Cartoon', 'Photography']

const Explore = () => {

  const [nfts, setNfts] = useState<Nft[] | undefined>(undefined)
  const [category, setCategory] = useState('')
  const [search, setSearch] = useState('')
  const searchRef = useRef<HTMLElement>(null)

  const searchNft = async () =>{
    try {
      const params = { // Параметры запроса
        user_uuid: '',
        in_blockchain: '1',
        category:'',
        name:search,
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await instance.get(`/nft/list/?${queryString}`)
      setNfts(response.data.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(()=>{
          searchNft()
  },[search])


  const getAllNfts = async () => {
    try {
      const params = { // Параметры запроса
        user_uuid: '',
        in_blockchain: '1',
        category:category
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await instance.get(`/nft/list/?${queryString}`)
      setNfts(response.data.data)
      console.log(nfts)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAllNfts()
  }, [category])

  return (
    <section className="artworks-area">
      <div className="container">
        <div className="row wow fadeInUp mb-4">
          <div className="col-lg-12">
            <div className="categories-bar pos-rel mb-30">
              <div className="swiper-container categories-bar-active">
                <div className="swiper-wrapper" style={{gap:'12px'}}>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src='/img/svg-icon/021-star.svg'
                          alt="icon-img"
                      />
                        All Items</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/014-artwork.svg"
                          alt="icon-img"
                      />
                        Creative Artworks</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/015-video.svg"
                          alt="icon-img"
                      />
                        Videos</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/016-music.svg"
                          alt="icon-img"
                      />
                        Music</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/017-video-game.svg"
                          alt="icon-img"
                      />
                        Games</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/018-software.svg"
                          alt="icon-img"
                      />
                        Software</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/019-photography.svg"
                          alt="icon-img"
                      />
                        Photography</a
                      >
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="category-item">
                      <a href="explore.html"
                      ><img
                          src="/img/svg-icon/020-laughing.svg"
                          alt="icon-img"
                      />
                        Cartoons</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="row wow fadeInUp">
          <div className="col-lg-12">

            <div className="art-filter-row">
              <div className="filter-by-search mb-30">
                <Select
                  label={'Categories'}
                  values={categories}
                  onChangeRaw={setCategory}
                  currentValue={category}
                  isLabel={false}
                  isSearchSelect
                />
                <SearchInput
                  inputProps={{
                    placeholder: 'Artwork name',
                    type: 'text',
                    value: search
                  }}
                  refElement={searchRef}
                  onChangeRaw={setSearch}
                  icon={<SearchIcon />}
                  withBackground
                />
              </div>
              <div className="filter-by-sale d-flex mb-30">
                <div className="select-category-title">
                  <i className="flaticon-filter"></i> Filters by
                </div>
                <select className="sale-category-select">
                  <option value="1">Auction</option>
                  <option value="2">Fixed</option>
                  <option value="3">On Sale</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className={'row wow fadeInUp'}>
          {nfts && nfts.length && nfts.map((nft, index) => (
            <SimpleNftCard
                nftUuid={nft.uuid}
                isLikeAuthUser={nft.isLikeAuthUser}
              owner={nft.owner}
              collection_address={nft.collection_address}
              key={index}
              nftPhoto={nft.image}
              nftName={nft.name}
              nftPrice={nft.price}
                countOfLikes={nft.likes}
              ownerPhoto={nft.user ? nft.user.avatar : ''}
              ownerUsername={nft.user ? nft.user.username : ''}
              userUuid={nft.user? nft.user.uuid : ''}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Explore;
