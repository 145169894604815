import React from 'react';
import SingleBannerHome from "../../components/SingleBannerHome";
import SimpleUserCardsWrapper from "../../components/SimpleUserCardsWrapper";
import SimpleNftsWrapper from "../../components/SimpleNftsWrapper";
const Home = () => {
  return (
    <section>
      <SingleBannerHome />
      <SimpleUserCardsWrapper />
      <SimpleNftsWrapper />
    </section>
  );
};

export default Home;