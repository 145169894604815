import {instance} from "./constants";

const setSCRFCookie = async () => {
  try {
    return await instance.get('sanctum/csrf-cookie',)
  } catch (e) {
  }
}

export default setSCRFCookie
