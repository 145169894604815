import React, {InputHTMLAttributes, ReactNode, ChangeEvent, useEffect} from 'react';
import styled from 'styled-components';
interface StyledInputProps {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  onChangeRaw: (newValue: string) => void;
  icon?: ReactNode,
  withBackground?: boolean
  refElement?: React.Ref<any>
}

const StyledInputDefaultProps = {
  withBackground: false
}

const InputWrapper = styled.div`
  position: relative;
`

const StyledInput = styled.input<{withBackground: boolean}>`
  width: 100%;
  border: ${p => p.withBackground ? 'none' : '1px solid #2d3136'};
  border-radius: ${p => p.withBackground ? '0 5px 5px 0' : '5px'};;
  background: ${p => p.withBackground ? '#fff' : 'none'};
  height: 50px;
  padding: 0 50px 0 20px;
  color: var(--clr-common-heading);
  outline: none;
  transition: all .3s ease-out 0s;
`

const InputIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;
  width: 20px;
  height: 20px;
`

const SearchInput = (props: StyledInputProps) => {

  const {
    inputProps: {
      placeholder,
      type,
      className,
      value,
    },
    refElement,
    onChangeRaw,
    icon,
    withBackground
  } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeRaw(e.target.value)
  }

  return (
    <InputWrapper>
      <StyledInput ref={refElement}
        withBackground={Boolean(withBackground)}
        onChange={handleInputChange}
        placeholder={placeholder}
        type={type}
        className={className}
        value={value}
      />
      <InputIcon>
        { icon && <div>{icon}</div> }
      </InputIcon>
    </InputWrapper>
  );
};

SearchInput.defaultProps = StyledInputDefaultProps;

export default SearchInput;
