import React, {useContext, useEffect, useRef, useState} from 'react';
import './index.scss';
import Button from "../common/Button";
import Input from "../common/SearchInput";
import {truncate} from '../../utils/common'
import {useWeb3React} from "@web3-react/core";
import styled, {css} from "styled-components";
import {useNavigate, Link} from 'react-router-dom'
import {RouteNames} from "../../router";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import BlackLogo from '../../icons/logo/logo-black.svg'
import Sidebar from "../Sidebar";
import NotificationContext from "../../context/NotificationContext";
import {instance} from "../../api/constants";
import SearchIcon from "../../icons/searchIcon";

const DropdownStyle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: var(--clr-bg-white);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 0%);
  border: 1px solid var(--clr-common-border);
  border-radius: 6px;
  overflow: hidden;
  transition: all .3s cubic-bezier(0.5, 0, 0, 1.25), opacity .3s ease-out;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;

  ${({isVisible}) => isVisible && css`
    max-height: max-content;
    opacity: 1;
    pointer-events: all;
  `};
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: var(--clr-common-black);
  font-weight: 400;
  line-height: 40px;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all .2s;

  &:hover, :focus, :focus:hover {
    background-color: var(--clr-common-border);
    outline: none;
    font-weight: 700;
  }
`;

const ButtonWrapper = styled.div`
  width: 180px;
`
const Header = () => {
  const {account, deactivate} = useWeb3React();
  const {displayNotification} = useContext(NotificationContext)
  const navigate = useNavigate()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [userCheck, setUserCheck] = useState(undefined)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isSidebarActive, setIsSidebarActive] = useState(false)

  const dropdownButtons: { value: string, to: string }[] = [
    {value: 'Profile', to: RouteNames.PERSONAL_INFO},
    {value: 'Upload Artwork', to: RouteNames.UPLOAD_ARTWORK},
  ]

  useOnClickOutside(dropdownRef, () => setIsDropdownVisible(false))


  const getUserData = async () => {
    try {
      const response = await instance.get('/user/me')

      console.log(response)
      if(response.status === 200){
        const address = response.data.data.address
        setUserCheck(address.slice(0,10))
        console.log(userCheck)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(()=>{
    getUserData()
  },[])




  const logout = async () => {
    try {
      const response = await instance.get('/logout')
      if (response.status === 200) {
        await deactivate()
        displayNotification(
          'default',
          'Success',
          'Logout was successful'
        )
        window.location.href = '/';
      }
    } catch (e) {
      displayNotification(
        'error',
        'Error',
        'Something went wrong'
      )
      console.log(e)
    }
  }

  return (
    <header className="header1">
      <div id="header-sticky" className="header-main header-main1">
        <div className="container header-container">
          <div className="header-main-left">
            <div className="header-logo header1-logo">
              <Link
                  to={RouteNames.HOME}
                  state={{prevRoute: window.location.pathname}}>
              <img src={BlackLogo} alt="Logo" className='logo-img'/>
              </Link>
            </div>
            <div className="filter-search-input header-search">
              <Input
                inputProps={{
                  placeholder: 'Collection Name',
                  type: 'text'
                }}
                onChangeRaw={() => {}}
                icon={<SearchIcon />}
              />
            </div>
          </div>
          <div className="header-main-right">
            <div className="main-menu main-menu1">
              <nav id="mobile-menu">
                <ul>
                  <li>
                    <Link
                      to={RouteNames.HOME}
                      className={"menu-item"}
                      state={{prevRoute: window.location.pathname}}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={RouteNames.EXPLORE}
                      className={"menu-item"}
                      state={{prevRoute: window.location.pathname}}>
                      Explore
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={RouteNames.FAQ}
                      className={"menu-item"}
                      state={{prevRoute: window.location.pathname}}
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={RouteNames.CONTACT}
                      className={"menu-item"}
                      state={{prevRoute: window.location.pathname}}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={'header-btn'}>
              <ButtonWrapper>
                <Button
                  fontSize={16}
                  background={'green'}
                  onClick={userCheck ?
                    () => setIsDropdownVisible(true)
                    :
                    () => navigate(RouteNames.CONNECT_WALLET)
                  }
                  isValid
                >
                  <span>{userCheck? userCheck : 'Connect Wallet'}</span>
                </Button>
              </ButtonWrapper>
              <DropdownStyle isVisible={Boolean(isDropdownVisible && userCheck)} ref={dropdownRef}>
                {dropdownButtons.map(button => (
                  <Link
                    key={button.to}
                    to={button.to}
                    state={{prevState: window.location.pathname}}
                  >
                    <DropdownItem
                      onClick={() => {
                        setIsDropdownVisible(false)
                      }}
                    >
                      {button.value}
                    </DropdownItem>
                  </Link>
                ))}
                <DropdownItem
                  onClick={() => {
                    logout()
                    setIsDropdownVisible(false)
                  }}
                >
                  Logout
                </DropdownItem>
              </DropdownStyle>
            </div>
            <div className="menu-bar" onClick={() => setIsSidebarActive(true)}>
              <div className="bar-icon side-toggle">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar isActive={isSidebarActive} setIsSidebarActive={setIsSidebarActive}/>
      <div className={`offcanvas-overlay ${isSidebarActive ? 'overlay-open' : ''}`}></div>
    </header>
  );
};

export default Header;
