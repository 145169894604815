import React, {useState, useRef} from 'react';
import styled, {css} from "styled-components";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

interface StyledSelectProps {
  label: string,
  values: any[],
  onChangeRaw: (newValue: string) => void,
  currentValue: string,
  isLabel?: boolean,
  classNames?: string[],
  isSearchSelect?: boolean,
}

const StyledSelectDefaultProps = {
  isLabel: true,
  isSearchSelect: false
}

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    display: block;
    line-height: 1;
    margin-bottom: 12px
  }
`;

const SelectLabelButton = styled.button<{isSearchSelect: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  width: 100%;
  border: 1px solid var(--clr-bg-white);
  border-radius: ${p => p.isSearchSelect? '5px  0 0 5px' : '5px'};
  background: var(--clr-bg-white);
  color: var(--clr-common-heading);
  font-size: 16px;
  padding: 0 20px;
  outline: none;
  box-shadow: none;
  
  span {
    color: #b4b4b4;
  }

  ${({isSearchSelect}) => isSearchSelect && css`
    border-right: 1px solid #e0e2e5;
  `};

  &:focus {
    border-color: var(--clr-bg-white);
    background: var(--clr-bg-white);
    box-shadow: 0 30px 50px rgba(28, 52, 94, .1);
    outline: none
  }
`;

const DropdownStyle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 100%;
  top: 115%;
  left: 0;
  background-color: var(--clr-bg-white);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 0%);
  border: 1px solid var(--clr-common-border);
  border-radius: 6px;
  overflow: hidden;
  transition: all .3s cubic-bezier(0.5, 0, 0, 1.25), opacity .3s ease-out;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;

  ${({isVisible}) => isVisible && css`
    max-height: max-content;
    opacity: 1;
    pointer-events: all;
  `};
`;

const DropdownItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: var(--clr-common-black);
  font-weight: 400;
  line-height: 40px;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all .2s;

  ${({isActive}) => isActive && css`
    font-weight: 700;
  `};

  &:hover, :focus, :focus:hover {
    background-color: var(--clr-common-border);
    outline: none;
    font-weight: 700;
  }
`;

const Select = (props: StyledSelectProps) => {
  const {label, values, onChangeRaw, currentValue, isLabel, isSearchSelect} = props;

  const [open, setOpen] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(selectRef, () => handleClose())

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value: any) => {
    if (onChangeRaw) onChangeRaw(value);
    handleClose();
  };

  return (
    <SelectContainer ref={selectRef}>
      {isLabel && <label>{label}</label>}
      <SelectLabelButton onClick={handleOpen} isSearchSelect={Boolean(isSearchSelect)}>
        {currentValue !== "" ? currentValue : <span>{label}</span>}
      </SelectLabelButton>
      <DropdownStyle isVisible={open}>
        {values.map((value, index) => (
          <DropdownItem
            onClick={() => handleChange(value)}
            isActive={value === currentValue}
            key={index}>
            {value}
          </DropdownItem>
        ))}
      </DropdownStyle>
    </SelectContainer>
  );
};

Select.defaultProps = StyledSelectDefaultProps

export default Select;