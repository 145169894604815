import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom'
import PersonalInfo from "../pages/PersonalInfo";
import {RouteNames} from "./index";
import WalletConnect from "../pages/WalletConnect";
import Contact from "../pages/Contact";
import FAQ from "../pages/FAQ";
import UploadArtwork from "../pages/UploadArtwork";
import Home from "../pages/Home";
import {useWeb3React} from "@web3-react/core";
import Explore from "../pages/Explore";
import UserProfile from "../pages/UserProfile";
import Web3 from "web3";

const AppRouter = () => {
  const [account, setAccount] = useState(false)
  const getAccount = async () => {
    const web3Instance = new Web3(window.ethereum);
    const accounts = await web3Instance.eth.getAccounts();
    if(accounts[0]){
      setAccount(true)
    }
  }
  useEffect(()=>{
    getAccount()
  },[])
  return (
    <>
      {
        account &&
          <Routes>
              <Route
                  path={RouteNames.HOME}
                  element={<Home/>}
              />
              <Route
                  element={<PersonalInfo/>}
                  path={RouteNames.PERSONAL_INFO}
              />
              <Route
                  element={<WalletConnect/>}
                  path={RouteNames.CONNECT_WALLET}
              />
              <Route
                  element={<Contact/>}
                  path={RouteNames.CONTACT}
              />
              <Route
                  element={<FAQ/>}
                  path={RouteNames.FAQ}
              />
              <Route
                  element={<UploadArtwork/>}
                  path={RouteNames.UPLOAD_ARTWORK}
              />
              <Route
                  element={<Explore/>}
                  path={RouteNames.EXPLORE}
              />
              <Route
                  element={<UserProfile/>}
                  path={RouteNames.CURRENT_USER}
              />
          </Routes>
      }
      {
        !account &&
          <Routes>
              <Route
                  path={RouteNames.HOME}
                  element={<Home/>}
              />
              <Route
                  element={<WalletConnect/>}
                  path={RouteNames.CONNECT_WALLET}
              />
              <Route
                  element={<Contact/>}
                  path={RouteNames.CONTACT}
              />
              <Route
                  element={<FAQ/>}
                  path={RouteNames.FAQ}
              />
              <Route
                  element={<WalletConnect/>}
                  path={'*'}
              />
              <Route
                  element={<Explore/>}
                  path={RouteNames.EXPLORE}
              />
              <Route
                  element={<UserProfile/>}
                  path={RouteNames.CURRENT_USER}
              />
          </Routes>
      }
    </>
  );
};

export default AppRouter;
