import React from 'react';
import LoaderIcon from '../../../icons/logo/logo.svg'
import PreloaderIcon from '../../../icons/logo/preloader.svg'
import './index.scss';
const Loader = () => {
  return (
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div
            className="loading-icon text-center d-flex flex-column align-items-center justify-content-center"
          >
            <img src={LoaderIcon} alt="logo-img" className='loading-logo-img'/>
            <img
              className="loading-logo"
              src={PreloaderIcon}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;